<template>
  <div
    class="displaybanner"
    :style="cssProps"
    :class="{
      'img-banner': imgBanner,
    }"
  >
    <div
      :class="{
        overlay: imgBanner,
      }"
    >
      <slot />
      <div class="text">
        <h1>{{ heading }}</h1>
        <h1>{{ subHeading }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DisplayBanner",
  components: {},
  props: {
    img: {
      type: String,
    },
    imgBanner: {
      type: Boolean,
    },
    heading: {
      type: String,
    },
    subHeading: {
      type: String,
    },
  },
  data() {
    return {
      cssProps: {
        backgroundImage: `url(${require(`@/assets/images/${this.img}`)})`,
        backgroundPosition: `50% 70%`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `cover`,
      },
    };
  },
  setup() {},
};
</script>

<style lang="scss" scoped>
@import "./DisplayBanner";
</style>
