import { getBuilderDetails } from 'api/buildersApi'

const BuilderDetailsPage = {
    state: ()=>({
        builderDetails:[],
    }),

    mutations:{
        setBuilderDetails(state, payload){
            state.builderDetails = payload
        },
    },

    getters:{

    },

    actions:{
        fetchBuilderDetails({commit}, obj){
            const builder_id = obj.builder_id;
            return getBuilderDetails({
                'builder_id':builder_id,
            }).then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setBuilderDetails', payload)
                        return payload
                    }
                })
        
        },
        
    }
}

export default BuilderDetailsPage