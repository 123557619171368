<template>
    <div
        class="builder-detailpage"
        v-if="Object.keys(builderDetails).length > 0"
    >
        <DisplayBanner
            imgBanner
            img="builders_page/builderslistpage_banner_img.jpg"
        />
        <div class="builderdetails-container">
            <div class="builderdetails-description">
                <HeadAndSubTexts
                    :heading="builderDetails.developer_data.name"
                    :subHeading="builderDetails.developer_data.description"
                />
            </div>
            <h1 class="ongoing-project">Ongoing Project</h1>
            <div class="builderdetails-row">
                <div
                    v-for="project in builderDetails.projects_data"
                    :key="project.id"
                    class="builderdetails-col"
                >
                    <ProjectCard :data="project" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import BuilderDetailsPageModule from 'store/modules/BuilderDetailsPage'
import IsVisible from 'mixins/IsVisible'

import DisplayBanner from 'componentsv2/DisplayBanner'
import HeadAndSubTexts from 'componentsv2/HeadAndSubTexts'
import ProjectCard from 'componentsv2/ProjectCard'

export default {
    name: 'BuilderDetailsPage',
    components: {
        DisplayBanner,
        HeadAndSubTexts,
        ProjectCard,
    },
    mixins: [RegisterStoreModule, IsVisible],
    data() {
        return {
            // currentPage: 1,
        }
    },
    computed: {
        ...mapState({
            builderDetails: state =>
                state.BuilderDetailsPageModule.builderDetails,
        }),
    },
    created() {
        this.registerStoreModule(
            'BuilderDetailsPageModule',
            BuilderDetailsPageModule,
        )
        this.fetchBuilderDetails({ builder_id: this.$route.params.id })
    },
    destroyed() {
        this.$store.unregisterModule('BuilderDetailsPageModule')
    },
    methods: {
        ...mapActions({
            fetchBuilderDetails: 'fetchBuilderDetails',
        }),
    },
}
</script>

<style lang="scss" scoped>
@import './BuilderDetailsPage.scss';
</style>
