<template>
    <div class="projectcard">
        <div class="projectcard-detail">
            <HeadAndSubTexts
                :heading2="data.name"
                :sub-heading="data.location"
                :sub-heading2="'₹ ' + data.cost"
            />
            <Button primary name="View Project" />
        </div>
        <div class="projectcard-showcase">
            <div
                v-for="(image, index) in [data.thumb, data.thumb2, data.thumb3]"
                class="primary-img"
                :key="'primary_img_' + index"
            >
                <img :src="image" />
            </div>
        </div>
    </div>
</template>

<script>
import Button from 'componentsv2/Button'
import HeadAndSubTexts from 'componentsv2/HeadAndSubTexts'

export default {
    name: 'ProjectCard',
    components: {
        Button,
        HeadAndSubTexts,
    },
    props: {
        data: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@import './ProjectCard.scss';
</style>
